
import { computed, createVNode, defineComponent, reactive, ref, watch } from 'vue';
import ForEditor from '@/components/for-editor';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import {
  adviser_sign_contract,
  adviser_sign_contract_view,
  create_address,
  create_bank_account,
  create_beneficiary,
  create_contract_for_adviser,
  create_tip_record,
  get_adviser_sign_contract,
  query_adviser_list,
  query_contract_list,
  update_adviser,
  update_bank_account,
  update_beneficiary,
} from '@/api/cerp-model';
import modalBox from '@/components/form-modal/modal-tools';
import { query_bank_manage_list, query_process_tip_list, query_tip_list } from '@/api/baseinfo-model';
import PageModel4 from '@/components/page-model4/index.vue';
import bus from '@/utils/bus';
import { useForm } from 'ant-design-vue/lib/form';
import { useI18n } from 'vue-i18n';
import { CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { GET_NO_SIGN_CONTRACT } from '@/store/modules/user/actions';
import router from '@/router';
import { check_init_password } from '@/api/sys-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { change_password } from '@/api/sys';

import locales from '@/locales';
import moment from 'moment';

export default defineComponent({
  components: {
    ForEditor,
    DeleteOutlined,
    ExclamationCircleOutlined,
    PageModel4,
    CheckCircleOutlined,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const {t} = useI18n();

    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const is_induction_process = computed(() => store.getters['user/is_induction_process']);
    const adviser = computed(() => store.getters[`user/adviser`]);
    const disabled: any = ref(false);
    const is_contract_old: any = ref(false);
    const disabled_adviser: any = ref(false);
    const confirm_disabled: any = ref(false);
    const is_bank_create: any = ref(true);
    const is_beneficiary_create: any = ref(true);
    const activeKey: any = ref('个人信息');
    const checked: any = ref(false);
    const checked_tip: any = ref(false);
    const checked_contract: any = ref(false);
    const checked_beneficiary: any = ref(false);
    const bankaccount_tip: any = ref(true);
    const beneficiary_tip: any = ref(true);
    const file_list: any = ref([]);
    const confirm: any = reactive({
      is_adviser: false,
      is_contract: false,
      is_bankaccount: false,
      is_beneficiary: false,
      is_tips: false,
    });
    const tip_id: any = ref(null);
    const contract_id: any = ref(null);
    const contract_url: any = ref('');
    const tip_url: any = ref('');
    let num: any = 0;

    const tags_list = ref([
      {
        key: '个人信息',
        tab1: t('page.Induction.personal_information'),
        slots: {tab: 'is_adviser'},
      },
      {
        key: '银行账户',
        tab1: t('page.Induction.bank_account'),
        slots: {tab: 'is_bankaccount'},
      },
      {
        key: '保险受益人',
        tab1: t('page.Induction.Insurance_beneficiary'),
        slots: {tab: 'is_beneficiary'},
      },
      {
        key: '重要提示',
        tab1: t('page.Induction.Important_tips'),
        slots: {tab: 'is_tips'},
      },
      {
        key: '合同签署',
        tab1: t('page.Induction.Contract_signing'),
        slots: {tab: 'is_contract'},
      },
    ])
    const get_no_sign_contract = () => {
      get_adviser_sign_contract({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
      }).then((res: any) => {
        let cc_company_id = null;
        if(res && (!res.qiyuesuo_status || res.qiyuesuo_status < 3)){
            cc_company_id = res.cc_company_id;
          store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: true })
        }else{
          store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: false })
        }
        query_tip_list({
          org_id: current_org.id,
          is_active: true,
          cc_company_id: res.cc_company_id,
          sort_fields: ['-id'],
        }).then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            tip_id.value = res.data[0].id;
            tip_url.value = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + res.data[0].files_json.file_url;
          }
        })
      })
    }
    get_no_sign_contract();
    get_adviser_sign_contract({
      org_id: current_org.id,
      adviser_id: adviser.value.adviser_id,
    }).then((res: any) => {
      if (res) {
        contract_id.value = res.id;
        create_contract_for_adviser({
          org_id: current_org.id,
          contract_id: res.id,
        }).then((res: any) => {
          if (res) {
            contract_url.value = res;
          }
        });
      }
    })
    query_contract_list({
      org_id: current_org.id,
      adviser_id: adviser.value.adviser_id,
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        res.data.forEach((item: any) => {
          if(item.confirm_date){
            confirm_disabled.value = true;
            confirm.is_beneficiary = true;
            confirm.is_bankaccount = true;
            confirm.is_adviser = true;
            is_contract_old.value = true;
            tags_list.value = [
              {
                key: '个人信息',
                tab1: t('page.Induction.personal_information'),
                slots: {tab: 'is_adviser'},
              },
              {
                key: '重要提示',
                tab1: t('page.Induction.Important_tips'),
                slots: {tab: 'is_tips'},
              },
              {
                key: '合同签署',
                tab1: t('page.Induction.Contract_signing'),
                slots: {tab: 'is_contract'},
              },
            ]
          }
        })
      }
    })
    const alert: any = reactive({
      is_adviser: false,
      is_bankaccount: false,
      is_beneficiary: false,
      is_tips: false,
      is_contract: false,
    });
    const submit: any = reactive({
      is_adviser: true,
      is_bankaccount: false,
      is_beneficiary: false,
    });
    const preservation: any = reactive({
      is_adviser: false,
      is_bankaccount: true,
      is_beneficiary: true,
    });
    query_process_tip_list({
      org_id: current_org.id,
      is_active: true,
    }).then((res: any) => {
      if(res && res.data && res.data.length > 0){
        res.data.map((item: any) => {
          if(item.name == '个人信息'){
            alert.adviser = item.content;
            alert.is_adviser = true;
          }else if(item.name == '银行账户'){
            alert.bankaccount = item.content;
            alert.is_bankaccount = true;
          }else if(item.name == '保险受益人'){
            alert.beneficiary = item.content;
            alert.is_beneficiary = true;
          }else if(item.name == '重要提示'){
            alert.tips = item.content;
            alert.is_tips = true;
          }else if(item.name == '合同签署'){
            alert.contract = item.content;
            alert.is_contract = true;
          }
        })
      }
    })
    const form_xin_xi = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [12, 12],
      },
      fields: [
        {
          type: 'kong_title',
          height: 30,
        },
        {
          type: 'input',
          name: 'full_name',
          label_i18n: 'page.Induction.form.label.full_name',
          label: '姓名',
          disabled: true,
          placeholder: '请填写姓名',
          placeholder_i18n: 'page.Induction.form.placeholder.full_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'input',
          name: 'other_name',
          label_i18n: 'page.Induction.form.label.other_name',
          label: '中文姓名',
          disabled: confirm_disabled,
          placeholder: '请填写中文姓名',
          placeholder_i18n: 'page.Induction.form.placeholder.other_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'remoteselect',
          name: 'nationality',
          label: '国籍',
          label_i18n: 'page.Induction.form.label.nationality',
          disabled: confirm_disabled,
          placeholder: '请选择国籍',
          placeholder_i18n: 'page.Induction.form.placeholder.nationality',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-country',
        },
        {
          type: 'input',
          name: 'mobile',
          label: '手机',
          label_i18n: 'page.Induction.form.label.mobile',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写手机',
          placeholder_i18n: 'page.Induction.form.placeholder.mobile',
        },
        {
          type: 'remoteselect',
          name: 'person_type_id',
          label: '个人类别',
          label_i18n: 'page.Induction.form.label.person_type_id',
          disabled: true,
          placeholder: '请选择个人类别',
          placeholder_i18n: 'page.Induction.form.placeholder.person_type_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-persontype',
        },
        {
          type: 'kong_title',
          height: 30,
        },
        {
          type: 'input',
          name: 'wechat',
          label: '微信账号',
          label_i18n: 'page.Induction.form.label.wechat',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写微信账号',
          placeholder_i18n: 'page.Induction.form.placeholder.wechat',
        },
        {
          type: 'input',
          name: 'qq',
          label: 'QQ账号',
          label_i18n: 'page.Induction.form.label.qq',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写QQ账号',
          placeholder_i18n: 'page.Induction.form.placeholder.qq',
        },
        {
          type: 'input',
          name: 'personal_email',
          label: '个人邮箱',
          label_i18n: 'page.Induction.form.label.personal_email',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写个人邮箱',
          placeholder_i18n: 'page.Induction.form.placeholder.personal_email',
        },
        {
          type: 'kong_title',
          height: 30,
        },
        {
          type: 'remoteselect',
          name: 'work_address_id',
          label: '工作地址',
          label_i18n: 'page.Induction.form.label.work_address_id',
          disabled: confirm_disabled,
          placeholder: '请选择工作地址',
          placeholder_i18n: 'page.Induction.form.placeholder.work_address_id',
          datasourceType: 'remote',
          mode: 'default',
          init_model: {adviser_id: adviser.value.adviser_id, address_type: 1},
          allowClear: true,
          labelKey: 'display_name',
          valueKey: 'id',
          modalType: 'cerp-address',
          cb: () => {
            return new Promise(function (resolve) {
              const editModal1 = reactive({
                visible: true,
                title: t('page.Induction.form.title.New_work'),
                loading: false,
                form: {
                  settings: {
                    labelAlign: 'right',
                    layout: 'vertical',
                    col: [],
                  },
                  fields: [
                    {
                      type: 'remoteselect',
                      name: 'country_id',
                      label: '国家',
                      label_i18n: 'page.Induction.form.label.work_address_id.country_id',
                      disabled: false,
                      placeholder: '请选择国家',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.country_id',
                      datasourceType: 'remote',
                      mode: 'default',
                      allowClear: true,
                      modalType: 'baseinfo-country',
                    },
                    {
                      type: 'input',
                      name: 'state',
                      label: '州/省',
                      label_i18n: 'page.Induction.form.label.work_address_id.state',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写州/省',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.state',
                    },
                    {
                      type: 'input',
                      name: 'city',
                      label: '城市',
                      label_i18n: 'page.Induction.form.label.work_address_id.city',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写城市',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.city',
                    },
                    {
                      type: 'input',
                      name: 'street',
                      label: '街道',
                      label_i18n: 'page.Induction.form.label.work_address_id.street',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写街道',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.street',
                    },
                    {
                      type: 'input',
                      name: 'postcode',
                      label: '邮编',
                      label_i18n: 'page.Induction.form.label.work_address_id.postcode',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写邮编',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.postcode',
                    },
                  ],
                  rules: {},
                  model: reactive({
                    org_id: current_org?.id,
                    is_active: true,
                    adviser_id: adviser.value.adviser_id,
                    adviser_name: null,
                    street: null,
                    city: null,
                    state: null,
                    country_id: null,
                    postcode: null,
                    address_type: 1,
                  }),
                },
                options: {
                  address_type: [
                    {value: 0, name: t('page.Induction.form.label.home_address_id')},
                    {value: 1, name: t('page.Induction.form.label.work_address_id')},
                  ],
                },
                ok: (payload: any) => {
                  editModal1.loading = true;
                  return new Promise(resolve1 => {
                    create_address(payload)
                      .then((res: any) => {
                        message.success(t('page.Induction.form.title.Created_successfully'));
                        resolve1(null);
                        resolve(res.id as number);
                      })
                      .finally(() => {
                        editModal1.loading = false;
                      });
                  });
                },
              });
              modalBox(editModal1);
            });
          },
        },
        {
          type: 'title',
          name: '个人信息',
          name_i18n: 'page.Induction.personal_information',
        },
        {
          type: 'select',
          name: 'title',
          label: '称谓',
          label_i18n: 'page.Induction.form.label.title',
          mode: 'default',
          disabled: confirm_disabled,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择称谓',
          placeholder_i18n: 'page.Induction.form.placeholder.title',
          datasource: 'title',
        },
        {
          type: 'datepicker',
          name: 'birth_date',
          label: '出生日期',
          label_i18n: 'page.Induction.form.label.birth_date',
          disabled: confirm_disabled,
          disabledTime: (date: any) => {
            return date >= moment();
          },
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请选择出生日期',
          placeholder_i18n: 'page.Induction.form.placeholder.birth_date',
        },
        {
          type: 'input',
          name: 'phone',
          label: '电话',
          label_i18n: 'page.Induction.form.label.phone',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写电话',
          placeholder_i18n: 'page.Induction.form.placeholder.phone',
        },
        {
          type: 'select',
          name: 'status',
          label: '个人状态',
          label_i18n: 'page.Induction.form.label.status',
          mode: 'default',
          disabled: true,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择个人状态',
          placeholder_i18n: 'page.Induction.form.placeholder.status',
          datasource: 'status',
        },
        {
          type: 'remoteselect',
          name: 'pay_currency_id',
          labelKeyList: ['code', 'currency'],
          label: '付款币种',
          label_i18n: 'page.Induction.form.label.pay_currency_id',
          disabled: true,
          placeholder: '请选择付款币种',
          placeholder_i18n: 'page.Induction.form.placeholder.pay_currency_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-country',
        },
        {
          type: 'title',
          name: '联系方式',
          name_i18n: 'page.Induction.information',
        },
        {
          type: 'input',
          name: 'whats_app',
          label: 'WhatsApp账号',
          label_i18n: 'page.Induction.form.label.whats_app',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写WhatsApp账号',
          placeholder_i18n: 'page.Induction.form.placeholder.whats_app',
        },
        {
          type: 'input',
          name: 'skypelid',
          label: 'Skype ID',
          label_i18n: 'page.Induction.form.label.skypelid',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写Skype ID',
          placeholder_i18n: 'page.Induction.form.placeholder.skypelid',
        },
        {
          type: 'input',
          name: 'work_email',
          label: '工作邮箱',
          label_i18n: 'page.Induction.form.label.work_email',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写工作邮箱',
          placeholder_i18n: 'page.Induction.form.placeholder.work_email',
        },
        {
          type: 'title',
          name: '个人地址',
          name_i18n: 'page.Induction.personal_address',
        },
        {
          type: 'remoteselect',
          name: 'home_address_id',
          label: '家庭地址',
          label_i18n: 'page.Induction.form.label.home_address_id',
          disabled: confirm_disabled,
          placeholder: '请选择家庭地址',
          placeholder_i18n: 'page.Induction.form.placeholder.home_address_id',
          datasourceType: 'remote',
          mode: 'default',
          init_model: {adviser_id: adviser.value.adviser_id, address_type: 0},
          allowClear: true,
          labelKey: 'display_name',
          valueKey: 'id',
          modalType: 'cerp-address',
          cb: () => {
            return new Promise(function (resolve) {
              const editModal1 = reactive({
                visible: true,
                title: t('page.Induction.form.title.New_home'),
                loading: false,
                form: {
                  settings: {
                    labelAlign: 'right',
                    layout: 'vertical',
                    col: [],
                  },
                  fields: [
                    {
                      type: 'remoteselect',
                      name: 'country_id',
                      label: '国家',
                      label_i18n: 'page.Induction.form.label.work_address_id.country_id',
                      disabled: false,
                      placeholder: '请选择国家',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.country_id',
                      datasourceType: 'remote',
                      mode: 'default',
                      allowClear: true,
                      modalType: 'baseinfo-country',
                    },
                    {
                      type: 'input',
                      name: 'state',
                      label: '州/省',
                      label_i18n: 'page.Induction.form.label.work_address_id.state',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写州/省',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.state',
                    },
                    {
                      type: 'input',
                      name: 'city',
                      label: '城市',
                      label_i18n: 'page.Induction.form.label.work_address_id.city',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写城市',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.city',
                    },
                    {
                      type: 'input',
                      name: 'street',
                      label: '街道',
                      label_i18n: 'page.Induction.form.label.work_address_id.street',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写街道',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.street',
                    },
                    {
                      type: 'input',
                      name: 'postcode',
                      label: '邮编',
                      label_i18n: 'page.Induction.form.label.work_address_id.postcode',
                      disabled: false,
                      allowClear: true,
                      inputType: 'text',
                      defaultValue: '',
                      placeholder: '请填写邮编',
                      placeholder_i18n: 'page.Induction.form.placeholder.work_address_id.postcode',
                    },
                  ],
                  rules: {},
                  model: reactive({
                    org_id: current_org?.id,
                    is_active: true,
                    adviser_id: adviser.value.adviser_id,
                    adviser_name: null,
                    street: null,
                    city: null,
                    state: null,
                    country_id: null,
                    postcode: null,
                    address_type: 0,
                  }),
                },
                options: {
                  address_type: [
                    {value: 0, name: t('page.Induction.form.label.home_address_id')},
                    {value: 1, name: t('page.Induction.form.label.work_address_id')},
                  ],
                },
                ok: (payload: any) => {
                  editModal1.loading = true;
                  return new Promise(resolve1 => {
                    create_address(payload)
                      .then((res: any) => {
                        message.success(t('page.Induction.form.title.Created_successfully'));
                        resolve1(null);
                        resolve(res.id as number);
                      })
                      .finally(() => {
                        editModal1.loading = false;
                      });
                  });
                },
              });
              modalBox(editModal1);
            });
          },
        },
      ],
      rules: {
        birth_date: [{ required: true, message: t('page.Induction.form.placeholder.birth_date') }],
        mobile: [{ required: true, message: t('page.Induction.form.placeholder.mobile') }],
        nationality: [{ required: true, message: t('page.Induction.form.placeholder.nationality') }],
        home_address_id: [{ required: true, message: t('page.Induction.form.placeholder.home_address_id') }],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: adviser.value.adviser_id,
        nationality: null,
      }),
      watch: {
        mobile: (data: any) => {
          if (data?.mobile && data.mobile.includes('+')) {
            data.mobile = data.mobile.replace(/\+/g, '00');
          }
        },
      },
    })
    const options_xin_xi = reactive({
      title: [
        {value: '', name: '-None-'},
        {value: 'Mr.', name: 'Mr.'},
        {value: 'Mrs.', name: 'Mrs.'},
        {value: 'Ms.', name: 'Ms.'},
        {value: 'Dr.', name: 'Dr.'},
        {value: 'Prof.', name: 'Prof.'},
      ],
      status: [
        {value: 1, name: t('page.Induction.options.status_1')},
        {value: 2, name: t('page.Induction.options.status_2')},
        {value: 3, name: t('page.Induction.options.status_3')},
        {value: 4, name: t('page.Induction.options.status_4')},
        {value: 5, name: t('page.Induction.options.status_5')},
        {value: 6, name: t('page.Induction.options.status_6')},
        {value: 7, name: t('page.Induction.options.status_7')},
        {value: 8, name: t('page.Induction.options.status_8')},
      ],
    });
    const {resetFields, validate, validateInfos} = useForm(form_xin_xi.model, form_xin_xi.rules);
    const local = ref(locales.global.locale);
    const form_yin_hang_ZH = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [7, 6],
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'bankmanage_id',
          label: '选择银行',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bankmanage_id',
          disabled: confirm_disabled,
          placeholder: '请选择银行名称',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bankmanage_id',
          datasourceType: 'remote',
          show_search: true,
          mode: 'default',
          allowClear: true,
          labelKey: local.value == 'en-US' ? 'english_name' : 'name',
          valueKey: 'id',
          modalType: 'baseinfo-bankmanage',
        },
        {
          type: 'input',
          name: 'bank_name',
          label: '银行名称',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bank_name',
          disabled: disabled,
          placeholder: '请选择银行名称',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bank_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'input',
          name: 'english_name',
          label: '银行英文名称',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.english_name',
          disabled: disabled || confirm_disabled,
          placeholder: '请选择银行英文名称',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.english_name',
          datasourceType: 'remote',
          allowClear: false,
        },
        {
          type: 'input',
          name: 'account_holder',
          label: '账户持有人',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.account_holder',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写账户持有人',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.account_holder',
        },
        {
          type: 'input',
          name: 'account_number',
          label: '账号',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.account_number',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写账号',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.account_number',
        },
        {
          type: 'textarea',
          name: 'note',
          label: '备注',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.note',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          maxlength: 200,
          minRows: 4,
          defaultValue: '',
          placeholder: '请填写备注',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.note',
        },
        {
          type: 'input',
          name: 'bank_address',
          label: '开户行地址',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bank_address',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写开户行地址',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bank_address',
        },
        {
          type: 'input',
          name: 'bic_or_swift_code',
          label: 'BIC/SWIFT CODE',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.bic_or_swift_code',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写BIC/SWIFT CODE',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.bic_or_swift_code',
        },
        {
          type: 'input',
          name: 'iban',
          label: 'IBAN',
          disabled: confirm_disabled,
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.iban',
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写IBAN',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.iban',
        },
        {
          type: 'input',
          name: 'sort_code_or_branch_code',
          label: 'SORT/BRANCH CODE',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.sort_code_or_branch_code',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写SORT/BRANCH CODE',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.sort_code_or_branch_code',
        },
        {
          type: 'input',
          name: 'cnaps',
          label: 'CNAPS',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.cnaps',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写CNAPS',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.cnaps',
        },
        {
          type: 'radio',
          name: 'is_current',
          label: '是否当前账号',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.is_current',
          disabled: confirm_disabled,
          allowClear: true,
          labelKey: 'name',
          valueKey: 'value',
          defaultValue: '',
        },
        {
          type: 'textarea',
          name: 'holder_address',
          label: '开户时向银行提供的住址',
          label_i18n: 'page.Induction.form_yin_hang_ZH.label.holder_address',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          maxlength: 512,
          minRows: 4,
          defaultValue: '',
          placeholder: '请填写开户时向银行提供的住址',
          placeholder_i18n: 'page.Induction.form_yin_hang_ZH.placeholder.holder_address',
        },
      ],
      rules: {
        bank_name: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_name')}],
        account_holder: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_holder')}],
        account_number: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_number')}],
        bank_address: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_address')}],
        is_current: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.is_current')}],
        bankmanage_id: [{required: true, message: t('page.Induction.form_yin_hang_ZH.placeholder.bankmanage_id')}],
        bic_or_swift_code: [{required: true, message: t('page.Induction.form_yin_hang_ZH.placeholder.bic_or_swift_code')}],
        holder_address: [{required: true, message: t('page.Induction.form_yin_hang_ZH.placeholder.holder_address')}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        bankmanage_id: null,
        is_current: true,
        cnaps: null,
        sort_code_or_branch_code: null,
        iban: null,
        bank_address: null,
        bic_or_swift_code: null,
        english_name: null,
        bank_name: null,
        holder_address: null,
        note: null,
        account_holder: null,
        account_number: null,
      }),
      watch: {
        bankmanage_id: (data1: any) => {
          if (data1.bankmanage_id) {
            query_bank_manage_list({
              org_id: current_org?.id,
              ids: [data1.bankmanage_id].join(','),
            }).then((res: any) => {
              if (res.data[0].name == '其它') {
                if(confirm_disabled.value){
                  disabled.value = true;
                }else{
                  disabled.value = false;
                  data1['bank_name'] = '';
                  data1['english_name'] = '';
                  data1['bic_or_swift_code'] = '';
                }
              } else {
                disabled.value = true;
                data1['bank_name'] = local.value == 'en-US' ? res.data[0].english_name : res.data[0].name;
                data1['english_name'] = res.data[0].english_name;
                data1['bic_or_swift_code'] = res.data[0].swift_code;
              }
            });
          } else {
            disabled.value = false;
            data1['bank_name'] = '';
            data1['english_name'] = '';
            data1['bic_or_swift_code'] = '';
          }

        },
      },
    })
    const options_yin_hang_ZH = reactive({
      is_current: [
        {value: true, name: t('page.Induction.options_yin_hang_ZH.is_current.true')},
        {value: false, name: t('page.Induction.options_yin_hang_ZH.is_current.false')},
      ],
    });
    const {
      resetFields: resetFields1,
      validate: validate1,
      validateInfos: validateInfos1
    } = useForm(form_yin_hang_ZH.model, form_yin_hang_ZH.rules);
    const form_Insurance_beneficiary = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'horizontal',
        labelCol: {
          span: 10,
        },
        wrapperCol: {
          span: 14,
        },
        col: [4, 4],
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '姓名',
          label_i18n: 'page.cerp.beneficiary.form.label.name',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写姓名',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.name',
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性别',
          label_i18n: 'page.cerp.beneficiary.form.label.gender',
          disabled: confirm_disabled,
          labelKey: 'name',
          valueKey: 'value',
          defaultValue: '',
        },
        {
          type: 'datepicker',
          name: 'date_of_birth',
          label: '出生日期',
          label_i18n: 'page.cerp.beneficiary.form.label.date_of_birth',
          disabled: confirm_disabled,
          allowClear: true,
          disabledTime: (date: any) => {
            return date >= moment();
          },
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请填写出生日期',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.date_of_birth',
        },
        {
          type: 'input',
          name: 'ratio',
          label: '受益比例(%)',
          label_i18n: 'page.cerp.beneficiary.form.label.ratio',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写受益比例(%)',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.ratio',
        },
        {
          type: 'remoteselect',
          name: 'id_type_id',
          label: '证件类别',
          label_i18n: 'page.cerp.beneficiary.form.label.id_type_id',
          disabled: confirm_disabled,
          placeholder: '请选择证件类别',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.id_type_id',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'baseinfo-idtype',
        },
        {
          type: 'input',
          name: 'id_number',
          label: '证件号',
          label_i18n: 'page.cerp.beneficiary.form.label.id_number',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写证件号',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.id_number',
        },
        {
          type: 'datepicker',
          name: 'id_expiry_date',
          label: '证件有效期',
          label_i18n: 'page.cerp.beneficiary.form.label.id_expiry_date',
          disabled: confirm_disabled,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          showTime: false,
          valueFormat: 'YYYY-MM-DD',
          placeholder: '请填写证件有效期',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.id_expiry_date',
        },
        {
          type: 'select',
          name: 'relation',
          label: '与被保险人关系',
          label_i18n: 'page.cerp.beneficiary.form.label.relation',
          mode: 'default',
          disabled: confirm_disabled,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择与被保险人关系',
          datasource: 'relation',
          placeholder_i18n: 'page.cerp.beneficiary.form.placeholder.relation',
        },
      ],
      rules: {
        name: [{required: true, message: t('page.Induction.rules.beneficiary.required')}],
        gender: [{required: true, message: t('page.Induction.rules.beneficiary.gender')}],
        date_of_birth: [{required: true, message: t('page.Induction.rules.beneficiary.date_of_birth')}],
        id_number: [{required: true, message: t('page.Induction.rules.beneficiary.id_number')}],
        relation: [{required: true, message: t('page.cerp.beneficiary.form.placeholder.relation')}],
        id_type_id: [{required: true, message: t('page.cerp.beneficiary.form.placeholder.id_type_id')}],
        ratio: [{required: true, message: t('page.cerp.beneficiary.form.placeholder.ratio')}],
      },
      model: reactive({
        org_id: current_org?.id,
        adviser_id: Number(adviser.value.adviser_id),
        name: null,
        relation: null,
        gender: null,
        date_of_birth: null,
        id_type_id: null,
        id_number: null,
        id_expiry_date: null,
        ratio: null,
      }),
    })
    const options_Insurance_beneficiary = reactive({
      gender: [
        {value: 0, name: t('page.Induction.options_Insurance_beneficiary.gender0')},
        {value: 1, name: t('page.Induction.options_Insurance_beneficiary.gender1')},
      ],
      relation: [
        {value: 0, name: t('page.Induction.options_Insurance_beneficiary.relation0')},
        {value: 1, name: t('page.Induction.options_Insurance_beneficiary.relation1')},
        {value: 2, name: t('page.Induction.options_Insurance_beneficiary.relation2')},
        {value: 3, name: t('page.Induction.options_Insurance_beneficiary.relation3')},
        {value: 4, name: t('page.Induction.options_Insurance_beneficiary.relation4')},
      ],
    });
    const {
      resetFields: resetFields4,
      validate: validate4,
      validateInfos: validateInfos4
    } = useForm(form_Insurance_beneficiary.model, form_Insurance_beneficiary.rules);
    query_adviser_list({
      org_id: current_org.id,
      ids: [adviser.value.adviser_id],
    }).then((res: any) => {
      if (res && res.data && res.data.length > 0) {
        Object.assign(form_xin_xi.model, res.data[0]);
        // form_xin_xi.model.nationality = res.data[0].home_address__country_id;
        setTimeout(() => {
          validate().then(() => {
            submit.is_adviser = false;
            preservation.is_adviser = true;
          });
        }, 1000);
      }
    })
    const search = reactive({
      current: 1,
      pageSize: 10,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        record: {},
        is_active: true,
        num: null,
      },
    });
    const scgyfangan = reactive({
      title: t('page.Induction.bankaccount'),
      column_flag: 'bankaccount',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-bankaccount',
      height: 500,
      mode: null,
    });
    const beneficiary = reactive({
      title: t('page.Induction.beneficiary'),
      column_flag: 'beneficiary',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'cerp-beneficiary',
      height: 500,
      mode: null,
    });
    const info = () => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'StatementModal',
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
      };
      modifyModal.cancel = () => {
      };
      modalBox(modifyModal);
    };
    bus.on('update_bank_account', obj => {
      Object.assign(form_yin_hang_ZH.model, {...obj, bank_account_id: obj.id});
      is_bank_create.value = false;
      (form_yin_hang_ZH.rules as any) = {};
    });
    bus.on('create_bank_account', () => {
      resetFields1();
      is_bank_create.value = true;
      (form_yin_hang_ZH.rules as any) = {
        bank_name: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_name')}],
        account_holder: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_holder')}],
        account_number: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.account_number')}],
        bank_address: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.bank_address')}],
        is_current: [{required: true, message: t('page.Induction.form_yin_hang_ZH.rules.is_current')}],
      };
    });

    bus.on('update_beneficiary', obj => {
      Object.assign(form_Insurance_beneficiary.model, {...obj, beneficiary_id: obj.id});
      is_beneficiary_create.value = false;
      (form_Insurance_beneficiary.rules as any) = {};
    });
    bus.on('create_beneficiary', () => {
      resetFields4();
      is_beneficiary_create.value = true;
      (form_Insurance_beneficiary.rules as any) = {
        name: [{required: true, message: t('page.Induction.rules.beneficiary.required')}],
        gender: [{required: true, message: t('page.Induction.rules.beneficiary.gender')}],
        date_of_birth: [{required: true, message: t('page.Induction.rules.beneficiary.date_of_birth')}],
        id_number: [{required: true, message: t('page.Induction.rules.beneficiary.id_number')}],
      };
    });

    // 个人顾问信息保存
    const handleUpdateAdviser = () => {
        validate().then(() => {
          update_adviser({
            ...form_xin_xi.model
          }).then((res: any) => {
            if (res && res.id) {
              Object.assign(form_xin_xi.model, res);
              message.success(t('page.Induction.message.adviser'));
              setTimeout(() => {
                confirm.is_adviser = false;
                submit.is_adviser = false;
                preservation.is_adviser = true;
              }, 1000);

            }
          })
        })
    }
    watch(() => form_xin_xi.model,
      () => {
        preservation.is_adviser = false;
        submit.is_adviser = true;
      },
      {
        deep: true,
      }
    )
    watch(() => form_yin_hang_ZH.model,
      () => {
        preservation.is_bankaccount = false;
        // submit.is_bankaccount = true;
      },
      {
        deep: true,
      }
    )
    watch(() => form_Insurance_beneficiary.model,
      () => {
        preservation.is_beneficiary = false;
        // submit.is_beneficiary = true;
      },
      {
        deep: true,
      }
    )

    // 银行账户信息 保存
    const handleUpdateBank = () => {
      if(checked.value){
        if (is_bank_create.value) {
          validate1().then(() => {
            create_bank_account({
              ...form_yin_hang_ZH.model,
            }).then(() => {
              message.success(t('page.Induction.message.bank'));
              (scgyfangan.search.requestParams.num as any) = num++;
              checked.value = false;
              resetFields1();
              setTimeout(() => {
                confirm.is_bankaccount = false;
                submit.is_bankaccount = false;
                preservation.is_bankaccount = true;
                // bankaccount_confirm.value = true;
              }, 1000);
            })
          })
        } else {
          validate1().then(() => {
            update_bank_account({
              ...form_yin_hang_ZH.model,
            }).then((res: any) => {
              message.success(t('page.Induction.message.adviser'));
              (scgyfangan.search.requestParams.num as any) = num++;
              checked.value = false;
              resetFields1();
              setTimeout(() => {
                confirm.is_bankaccount = false;
                submit.is_bankaccount = false;
                preservation.is_bankaccount = true;
                // bankaccount_confirm.value = true;
              }, 1000);
            })
          })
        }
      }else{
        notification.error({
          message: t('page.Induction.message.reminder'),
          description:
            t('page.Induction.agree'),
        });
      }

    }
    const handleUpdatePersonal = () => {

    }
    const handleUpdateBeneficiary = () => {
      if (is_beneficiary_create.value) {
        validate4().then(() => {
          create_beneficiary({
            ...form_Insurance_beneficiary.model,
          }).then(() => {
            message.success(t('page.Induction.message.bank'));
            (beneficiary.search.requestParams.num as any) = num++;
            resetFields4();
            setTimeout(() => {
              confirm.is_beneficiary = false;
              submit.is_beneficiary = false;
              preservation.is_beneficiary = true;
            }, 1000);
          })
        })
      } else {
        validate4().then(() => {
          update_beneficiary({
            ...form_Insurance_beneficiary.model,
          }).then((res: any) => {
            message.success(t('page.Induction.message.adviser'));
            (beneficiary.search.requestParams.num as any) = num++;
            resetFields4();
            setTimeout(() => {
              confirm.is_beneficiary = false;
              submit.is_beneficiary = false;
              preservation.is_beneficiary = true;
            }, 1000);
          })
        })
      }
    }
    const onTabChange = (value: string) => {
      // if(activeKey.value == '个人信息' && !confirm.is_adviser){
      //   Modal.confirm({
      //     title: t('page.Induction.message.reminder'),
      //     content: t('page.kefu.personal'),
      //     onOk() {
      //       confirm.is_adviser = true;
      //       activeKey.value = value;
      //     },
      //     onCancel() {
      //       confirm.is_adviser = false;
      //     },
      //   });
      // }else if(activeKey.value == '银行账户' && !confirm.is_bankaccount){
      //   Modal.confirm({
      //     title: t('page.Induction.message.reminder'),
      //     content: t('page.kefu.bank'),
      //     onOk() {
      //       confirm.is_bankaccount = true;
      //       activeKey.value = value;
      //     },
      //     onCancel() {
      //       confirm.is_bankaccount = false;
      //     },
      //   });
      // }else if(activeKey.value == '保险受益人' && !confirm.is_beneficiary){
      //   Modal.confirm({
      //     title: t('page.Induction.message.reminder'),
      //     content: t('page.kefu.beneficiary'),
      //     onOk() {
      //       confirm.is_beneficiary = true;
      //       activeKey.value = value;
      //     },
      //     onCancel() {
      //       confirm.is_beneficiary = false;
      //     },
      //   });
      // }else if(activeKey.value == '重要提示' && !confirm.is_tips){
      //   Modal.confirm({
      //     title: t('page.Induction.message.reminder'),
      //     content: t('page.kefu.tips'),
      //     onOk() {
      //       confirm.is_tips = true;
      //       activeKey.value = value;
      //     },
      //     onCancel() {
      //       confirm.is_tips = false;
      //     },
      //   });
      // }else{
      //   activeKey.value = value;
      // }
      if(value == '合同签署' && !confirm.is_tips){
        Modal.confirm({
          title: t('page.Induction.message.reminder'),
          content: t('page.kefu.tips'),
          onOk() {
            confirm.is_tips = true;
            activeKey.value = value;
          },
          onCancel() {
            confirm.is_tips = false;
          },
        });
      }else{
        activeKey.value = value;
      }
    }
    const handleIsTips = () => {
      confirm.is_tips = true;
      message.success(t('page.Induction.message.confirm'));
    };

    const handleSignContract = () => {
      let is_success: any = true;
      if (!confirm.is_adviser) {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description:
            t('page.Induction.adviser.description'),
        });
        is_success = false;
      }
      if (!confirm.is_bankaccount) {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description:
            t('page.Induction.bankaccount.description'),
        });
        is_success = false;
      }
      if (!confirm.is_beneficiary) {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description:
            t('page.Induction.beneficiary.description'),
        });
        is_success = false;
      }
      if (!confirm.is_tips) {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description:
            t('page.Induction.tips.description'),
        });
        is_success = false;
      }
        if (is_success) {
          Modal.confirm({
            title: t('page.Induction.Select_signing_method'),
            icon: createVNode(ExclamationCircleOutlined),
            cancelText: t('page.Induction.text'),
            okText: t('page.Induction.Finger'),
            onOk() {
              return new Promise(resolve => {
                const modifyModal: any = reactive({
                  visible: true,
                  component: 'SignModal',
                  title: t('page.Induction.Finger'),
                  ok: (data: any) => {
                    return new Promise(resolve1 => {
                      adviser_sign_contract({
                        org_id: current_org?.id,
                        sign_nsfile_id: data.fileid,
                        contract_id: contract_id.value,
                      }).then((res: any) => {
                        if (res) {
                          resolve1(null);
                          message.success(t('page.Induction.Signature_completed'));
                          message.success(t('page.Induction.Signing_contract_generation'));
                          confirm.is_contract = true;
                          adviser_sign_contract_view({
                            org_id: current_org?.id,
                            sign_nsfile_id: data.fileid,
                            contract_id: contract_id.value,
                          }).then((res: any) => {
                            contract_url.value = res;
                          })
                          create_tip_record({
                            org_id: current_org?.id,
                            adviser_id: Number(adviser.value.adviser_id),
                            tip_id: tip_id.value,
                            contract_id: contract_id.value,
                          })
                        }
                      })
                      resolve(null);
                    });
                  },
                  cancel: () => {
                    resolve(null);
                  },
                });
                modifyModal.visible = true;
                modalBox(modifyModal);
              })
            },
            onCancel() {
              return new Promise(resolve => {
                const editModal = reactive({
                  visible: true,
                  title: t('page.Induction.text'),
                  component: 'form-modal',
                  loading: false,
                  form: {
                    settings: {
                      labelAlign: 'right',
                      layout: 'vertical',
                      col: [],
                    },
                    fields: [
                      {
                        type: 'input',
                        name: 'sign_name',
                        label: 'Text',
                        label_i18n: 'page.Induction.text',
                        disabled: false,
                        allowClear: true,
                        inputType: 'text',
                        defaultValue: '',
                        placeholder: 'Text',
                        placeholder_i18n: 'page.Induction.text',
                      },
                    ],
                    rules: {},
                    model: reactive({
                      sign_name: null,
                    }),
                  },
                  options: {},
                  ok: (payload: any) => {
                    return new Promise(resolve1 => {
                      editModal.loading = true;
                      adviser_sign_contract({
                        org_id: current_org?.id,
                        sign_name: payload.sign_name,
                        contract_id: contract_id.value,
                      }).then((res: any) => {
                        if (res) {
                          resolve1(null);
                          message.success(t('page.Induction.Signature_completed'));
                          message.success(t('page.Induction.Signing_contract_generation'));
                          confirm.is_contract = true;
                          adviser_sign_contract_view({
                            org_id: current_org?.id,
                            sign_name: payload.sign_name,
                            contract_id: contract_id.value,
                          }).then((res: any) => {
                            contract_url.value = res;
                          })
                          create_tip_record({
                            org_id: current_org?.id,
                            adviser_id: Number(adviser.value.adviser_id),
                            tip_id: tip_id.value,
                            contract_id: contract_id.value,
                          })
                        }
                      })
                      resolve(null);
                      editModal.loading = false;
                    });
                  },
                  cancel: () => {
                    resolve(null);
                  },
                });
                modalBox(editModal);
              })
            },
          })
        }
    };
    const handleChangePassword = () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        loading: false,
        component: 'FormOkModal',
        title: t('page.login.Modify_default_password'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'horizontal',
          },
          fields: [
            {
              type: 'password',
              name: 'oldpassword',
              label: '旧密码',
              label_i18n: 'page.login.oldpassword',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder_i18n: 'page.login.enter',
            },
            {
              type: 'password',
              name: 'newpassword',
              label: '新密码',
              label_i18n: 'page.login.newpassword',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder_i18n: 'page.login.enter',
            },
            {
              type: 'password',
              name: 'newpassword2',
              label: '确认密码',
              label_i18n: 'page.login.newpassword2',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder_i18n: 'page.login.enter',
            },
          ],
          rules: reactive({
            oldpassword: [{ required: true, message: t('page.login.Please_oldpassword') }],
            newpassword: [{ required: true, message: t('page.login.Please_newpassword') }],
          }),
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            oldpassword: null,
            newpassword: null,
            newpassword2: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          if (payload.newpassword !== payload.newpassword2) {
            notification.error({
              message: t('page.login.reminder'),
              description: t('page.login.The_two_passwords_are_inconsistent'),
            });
            return false;
          }
          return new Promise(resolve => {
            editModal.loading = true;
            change_password(payload)
              .then(() => {
                message.success(t('page.login.Password_modified_successfully'),);
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const handleCompleteContract = () => {
      get_adviser_sign_contract({
        org_id: current_org.id,
        adviser_id: adviser.value.adviser_id,
      }).then((res: any) => {
        message.success(t('page.Induction.Signature_completed'));
        if(res && (!res.qiyuesuo_status || res.qiyuesuo_status < 3)){
          notification.error({
            message: t('page.Induction.message.reminder'),
            description:
              t('page.Induction.Exit_failed'),
          });
          store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: true });
        }else{
          store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: false });
          router.push({ path: '/adviser_workplace' });
          // 延迟 1 秒显示欢迎信息
          setTimeout(() => {
            notification.success({
              message: t('page.login.welcome'),
              description: t('page.login.welcome_back'),
            });
          }, 1000);
          check_init_password({
            org_id: current_org.id,
            user_id: adviser.value.adviser_user_id,
          }).then((res: any) => {
            if(res && res.need_change){
              handleChangePassword();
            }
          })
        }
      })
    }

    const gotoworkplace = () => {
      Modal.confirm({
        title: t('page.kefu.gotoworkplace1'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            router.push({ path: '/adviser_workplace' });
            setTimeout(() => {
              notification.success({
                message: t('page.login.welcome'),
                description: t('page.login.welcome_back'),
              });
            }, 1000);
            check_init_password({
              org_id: current_org.id,
              user_id: adviser.value.adviser_user_id,
            }).then((res: any) => {
              if(res && res.need_change){
                handleChangePassword();
              }
            })
            resolve(null);
          })
        },
        onCancel() {},
      });
    }
    return {
      t,
      tags_list,
      confirm_disabled,
      options_xin_xi,
      options_yin_hang_ZH,
      options_Insurance_beneficiary,
      handleUpdateAdviser,
      handleUpdatePersonal,
      handleUpdateBeneficiary,
      onTabChange,
      info,
      gotoworkplace,
      form_xin_xi,
      form_yin_hang_ZH,
      form_Insurance_beneficiary,
      disabled_adviser,
      scgyfangan,
      beneficiary,
      handleUpdateBank,
      handleCompleteContract,
      is_bank_create,
      is_beneficiary_create,
      validateInfos4,
      validateInfos1,
      validate,
      validateInfos,
      activeKey,
      checked,
      checked_tip,
      checked_contract,
      checked_beneficiary,
      file_list,
      contract_url,
      handleIsTips,
      handleSignContract,
      tip_url,
      confirm,
      is_induction_process,
      bankaccount_tip,
      beneficiary_tip,
      alert,
      submit,
      preservation,
      is_contract_old,
    };
  },
});
